import HeroSectionOne from "@components/hero-section/HeroSectionOne";
import { ServiceRaidHailing, ServicesContentRideHailingForHome, featuresForHome, homeScript1, homeScript2 } from "@utils/data";
import dynamic from "next/dynamic";
import Navbar from "@layout/Header/Navbar";
import Footer from "@layout/Footer/Footer";
const SupportOne = dynamic(()=>import('@components/support/SupportOne'),{ssr:false});
import Layout from "@layout/Layout";
import FeatureImgContentOne from "@components/feature-img-content/FeatureImgContentOne";
const CustomerTwo = dynamic(()=>import('@components/customer/CustomerTwo'),{ssr:false});
const OurStory = dynamic(()=>import('@components/our-story/OurStory'),{ssr:false});
const LatestBlog = dynamic(()=>import('@components/blogs/LatestBlog'),{ssr:false});
const FeatureHomeFive = dynamic(()=>import('@components/features/FeatureHomeFive'),{ssr:false});
const TestimonialTwo = dynamic(()=>import('@components/testimonial/TestimonialTwo'),{ssr:false});
const TabOneIndustriesCommonSection = dynamic(()=>import('@components/tabs/TabOneIndustriesCommonSection'),{ssr:false});
import imagesindex from "../assets/img/home/ogImage/ZervXBanner-og.jpeg";
const Home=()=> {
  return (
    <Layout
      title={"Taxi Dispatch Solution | Taxi Management Software | ZervX"}
      desc={"Looking for the most advanced taxi dispatch management software to launch and scale up your taxi business? ZervX is here to help you. Start your free trial now!"}
      keyWords={
        "taxi dispatch software, taxi software, white label taxi software, white label taxi app"
      }
      link={"https://www.zervx.com/"}
      script={homeScript1}
      ScriptTwo={homeScript2}
      images={imagesindex}
      >
      <Navbar navDark />
      <HeroSectionOne />
      <OurStory />
      <FeatureImgContentOne />
      <FeatureHomeFive
      subtitle="Smart software solutions for businesses"
      title="Tailored Solutions for Every Transportation Need"
      desc="If you're looking to improve your transportation operations and stay ahead of the competition,
      our software solutions are the answer."
      features={featuresForHome}
      />
      <TabOneIndustriesCommonSection
        subtitle="Tools to streamline operations"
        title="Accelerate Your Taxi Business With Our Taxi Dispatch System"
        description="Speed up your growth, delight your customers, and achieve lasting success in this dynamic and rapidly changing industry."
        Services={ServiceRaidHailing}
        ServicesContent={ServicesContentRideHailingForHome}
      />
      <TestimonialTwo
        sectionBgClass="bg-dark"
        swiperBgClass="bg-custom-light text-white"
      />
      <CustomerTwo />
      <LatestBlog />
      <SupportOne />
      <Footer footerGradient />
    </Layout>
  );
};
export default Home;